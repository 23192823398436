<template>
  <VueContentHolder
    v-if="showComponent"
    class="component-wrapper"
    :style="{
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      background: `url('${require('@/assets/bg/modal-bg.svg')}') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)`,
    }"
  >
    <VueContentHolder :padding="[20, 20]">
      <a @click.prevent="closeModal" class="close-button">
        <VueIcon
          :iconName="getIcon.cross.name"
          iconColor="#ffffff"
          :width="getIcon.cross.width"
          :height="getIcon.cross.height"
        ></VueIcon>
      </a>

      <div class="content-wrapper">
        <VueText weightLevel="10" sizeLevel="14" color="white-100" class="headline">{{
          `${amount} TL`
        }}</VueText>
        <VueSpacer level="40"></VueSpacer>
        <VueText weightLevel="3" sizeLevel="11" color="white-100"
          >İndirim cüzdanlarına <br />başarılı bir şekilde eklendi.</VueText
        >
        <VueSpacer level="40"></VueSpacer>
        <img src="~@/assets/images/icons/check.svg" alt="check" />
        <VueSpacer level="40"></VueSpacer>
        <VueText weightLevel="2" sizeLevel="10" color="white-100"
          >İndirimini bir sonraki ön sipariş gününde kullanabilirsin</VueText
        >
        <VueSpacer level="60"></VueSpacer>
      </div>
    </VueContentHolder>
  </VueContentHolder>
</template>

<script>
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';

export default {
  name: 'BrandConvertSuccessView',
  components: {
    VueSpacer,
    VueContentHolder,
    VueText,
    VueIcon,
  },
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: String,
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        cross: ICON_VARIABLES.cross,
      };
    },
  },
  methods: {
    closeModal(e) {
      this.$emit('close', e);
    },
  },
};
</script>

<style scoped lang="scss">
.component-wrapper {
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  top: 0;
  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .close-button {
    position: absolute;
    right: palette-space-level(20);
    top: palette-space-level(20);
  }
  .headline {
    font-size: 42px;
    font-weight: bold;
  }
}
</style>

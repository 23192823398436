<template>
  <div id="divPointToDiscountWrapper" class="convert-form">
    <VueForm ref="form">
      <VueContentHolder :padding="[20, 20, 40, 20]">
        <section class="section cards">
          <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
            >Hediye Kartım</VueHeadline
          >
          <div class="section-header">
            <div class="section-header-content">
              <VueText sizeLevel="10" weightLevel="4">{{ pointFormatter(totalPoints) }}</VueText>
            </div>
            <VueText color="grey-30" sizeLevel="6">Puan</VueText>
          </div>
        </section>
        <section class="section cards" v-if="!selectedCard">
          <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
            >Hangi Hediye Kartınızdan Puan Aktarmak İstersiniz?</VueHeadline
          >
          <div :class="{ 'cards-list': true, 'cards-list-toggled': isCardsToggled }">
            <div class="cards-list-select">
              <VueButton
                :iconName="icons.wallet.name"
                :iconSize="{ width: icons.wallet.width, height: icons.wallet.height }"
                :size="sizes.xLarge"
                iconColor="#e5472d"
                class="cards-list-select-textButton"
                @click="toggleList"
              >
                <VueText sizeLevel="6" weightLevel="4" color="red-10">Kart Seçiniz</VueText>
              </VueButton>
              <VueButton
                :key="isCardsToggled"
                :isIconButton="true"
                :iconName="isCardsToggled ? icons.chevronUpAlt.name : icons.chevronDownAlt.name"
                :size="sizes.xLarge"
                :iconSize="{
                  width: icons.chevronDownAlt.width,
                  height: icons.chevronDownAlt.height,
                }"
                iconColor="#e5472d"
                class="cards-list-select-downButton"
                @click="toggleList"
              >
              </VueButton>
            </div>
            <div class="cards-list-dropdown">
              <div
                class="cards-list-dropdown-item"
                v-for="card in customerDetail.partners"
                :key="card.code"
              >
                <VueButton
                  :size="sizes.xLarge"
                  :disabled="card.point > 0 ? false : true"
                  :rightIcon="card.point > 0 ? icons.right : null"
                  class="cards-list-select-textButton"
                  @click="selectCard(card)"
                >
                  <div
                    class="cards-list-image"
                    :style="{
                      backgroundImage: `url(${card.image})`,
                    }"
                  ></div>
                  <div v-if="card.point > 0" class="cards-list-select-textButton_rightText">
                    <VueText sizeLevel="7" weightLevel="3">{{
                      pointFormatter(card.point)
                    }}</VueText>
                    <div class="cards-list-select-points">
                      <VueText color="grey-30" sizeLevel="2">Puan</VueText>
                    </div>
                  </div>
                  <div v-else class="cards-list-select-textButton_rightText">
                    <VueText sizeLevel="3" weightLevel="4" color="#23303d">{{
                      `${card.desc} için indirime dönüştürülebilir puanınız bulunmamaktadır.`
                    }}</VueText>
                  </div>
                </VueButton>
              </div>
            </div>
          </div>
        </section>
        <section class="section cards" v-if="selectedCard">
          <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
            >İndirim Kartım</VueHeadline
          >
          <div :class="{ 'cards-list': true, 'cards-list-toggled': isCardsToggled }">
            <div class="cards-list-select">
              <VueButton :size="sizes.xLarge" class="cards-list-selectedCard">
                <div
                  class="cards-list-image"
                  :style="{
                    backgroundImage: `url(${selectedCard.image})`,
                    backgroundSize: '90%',
                  }"
                >
                  <VueIcon
                    :width="icons.checked.width"
                    :height="icons.checked.height"
                    :iconName="icons.checked.name"
                  />
                </div>
                <VueText sizeLevel="5" color="#23303d">{{ selectedCard.desc }}</VueText>
                <div class="cards-list-select-textButton_rightText">
                  <VueText sizeLevel="9" weightLevel="3">{{
                    pointFormatter(selectedCard.amount)
                  }}</VueText
                  ><VueText color="grey-30" sizeLevel="6">TL</VueText>
                </div>
              </VueButton>
            </div>
          </div>
          <BrandButton
            class="gift-card-button"
            @click="convertAllScore"
            iconColor="#fff"
            :iconName="icons.refresh.name"
            :iconSize="{ width: icons.refresh.width, height: icons.refresh.height }"
          >
            <VueText color="white-100" sizeLevel="6" weightLevel="2">Tüm Puanımı Dönüştür</VueText>
          </BrandButton>
          <div class="cards-convert">
            <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
              >Çevrilecek Puan Miktarını Giriniz
              <VueIcon
                :width="icons.refresh.width"
                :height="icons.refresh.height"
                :iconName="icons.refresh.name"
              />
            </VueHeadline>
            <!-- <div class="cards-convert-input">
              <input
                v-model="convertingValue"
                type="number"
                :placeholder="`Maksimum ${selectedCard.maxConvertableValue} Puan Çevrilebilir`"
              />
              <VueIcon
                :width="icons.info.width"
                :height="icons.info.height"
                :iconName="icons.info.name"
                color="#79838e"
              />
            </div> -->
            <VueInput
              type="number"
              class="cards-convert-input"
              name="convertingValue"
              id="convertingValue"
              validatorName="Puan Miktarı"
              :label="`Max. ${pointFormatter(totalPoints)} Puan`"
              v-model="convertingValue"
              :messageOnTop="true"
              ref="convertingValue"
              :validationRules="`required|numeric|between:1,${totalPoints}`"
              ><div id="divInfoClick" @click="showPointRules()">
                <VueIcon
                  :width="icons.info.width"
                  :height="icons.info.height"
                  :iconName="icons.info.name"
                  color="#79838e"
                /></div
            ></VueInput>
            <div class="cards-convert-quantity">
              <VueText color="grey-30" sizeLevel="4">Çevrilecek Miktar</VueText>
              <div class="cards-convert-quantity-value">
                <VueText sizeLevel="9" weightLevel="3">{{
                  pointFormatter(getConvertingQuantity)
                }}</VueText
                ><VueText color="grey-30" sizeLevel="6">TL</VueText>
              </div>
            </div>
          </div>
        </section>
      </VueContentHolder>
      <BrandButton
        v-if="selectedCard"
        :size="sizes.xxLarge"
        class="convert-button"
        type="submit"
        :padding="{ vertical: 15, horizontal: 0 }"
        @click.prevent="submitConvert"
        >DEVAM ET</BrandButton
      >
    </VueForm>
    <BrandConvertSuccessView
      :showComponent="showSuccessView"
      :amount="pointFormatter(getConvertingQuantity)"
      @close="closeSuccessModal"
    ></BrandConvertSuccessView>
    <BrandInfoModal :show.sync="pointRulesModal.show">
      <div class="modal-rules-wrapper" v-if="pointRulesModal.data">
        <div v-for="(rule, i) in pointRulesModal.data" :key="`rule_${i}`">
          <h1>{{ rule.title }}</h1>
          <p>{{ rule.desc }}</p>
        </div>
      </div>
    </BrandInfoModal>
  </div>
</template>
<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder';
import VueText from '@/components/shared/VueText/VueText';
import VueButton from '@/components/shared/VueButton/VueButton';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandConvertSuccessView from '@/components/brand/BrandConvertSuccessView/BrandConvertSuccessView';
import BrandInfoModal from '@/components/brand/Modals/BrandModal/BrandInfoModal.vue';
import {
  ICON_VARIABLES,
  PARTNER_IMAGES,
  COMPONENT_SIZES,
} from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

import { Finance } from '@/services/Api/index.js';
import StorageProps from '@/mixins/storageProps.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesMyWallets from '@/router/routes/secure/RoutesMyWallets';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { numberFormatter } from '@/utils/publicHelper.js';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'ConverScore',
  mixins: [StorageProps, gtmUtils],
  components: {
    VueContentHolder,
    VueText,
    VueButton,
    VueIcon,
    VueHeadline,
    BrandButton,
    VueForm,
    VueInput,
    BrandConvertSuccessView,
    BrandInfoModal,
  },
  beforeMount() {
    Finance.getPointDiscountRate().then(res => {
      const {
        Data: { pointPMDiscountRate },
      } = res.data;
      this.discountRate = pointPMDiscountRate;
    });
    if (this.$route.query && this.$route.query.code) {
      let card = this.customerDetail.partners.find(c => c.code === this.$route.query.code);
      this.selectCard(card);
    }
  },
  data() {
    return {
      discountRate: 0,
      walletsHomeURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.WalletsHome.path}`,
      convertingValue: null,
      selectedCard: null,
      isCardsToggled: false,
      showSuccessView: false,
      pointRulesModal: { show: false, data: null },
    };
  },
  methods: {
    pointFormatter(value) {
      return numberFormatter(value);
    },
    convertAllScore() {
      const modalConfig = {
        isModalOpened: true,
        modalText: `<div>Bütün puanınızı(<b>${this.pointFormatter(
          this.totalPoints,
        )}</b>) dönüştürmeyi onaylıyor musunuz?</div>`,
        isDoubleButton: true,
        firstButtonText: 'EVET',
        secondButtonText: 'İPTAL',
        modalConfirmationText: '',
        alertType: 'error',
        firstButtonFn: () => {
          this.convertPoints(true);
        },
      };
      this.$store.dispatch('app/setModalConfig', modalConfig);
    },
    showPointRules() {
      Finance.getPointRules(this.selectedCard.code).then(res => {
        if (res.data && res.data.Data && res.data.Data.pointRules) {
          this.pointRulesModal.data = res.data.Data.pointRules.map((item, index) => ({
            title: `Puan Kuralı ${index + 1}`,
            desc: item,
          }));
          this.pointRulesModal.show = true;
        } else {
          this.pointRulesModal.show = false;
        }
      });
    },
    toggleList() {
      this.isCardsToggled = !this.isCardsToggled;
    },
    selectCard(card) {
      if (card && card.desc) {
        this.selectedCard = card;
        this.$store.dispatch('app/setBottomBarStatus', false);
      }
    },
    closeSuccessModal() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('cuzdanlarim', {
        location: 'indirime_donustur_popup',
      });
      this.$router.push(this.walletsHomeURL);
    },
    async convertPoints(isConvertAllScore) {
      this.convertingValue = isConvertAllScore ? this.totalPoints.toString() : this.convertingValue;
      Finance.convertPointToDiscount(this.selectedCard.code, +this.convertingValue).then(res => {
        if (res.data && res.data.Data.result && res.data.Data.result.status == 1)
          this.showSuccessView = true;
        else {
          this.$store.dispatch('app/setModalConfig', {});
        }
      });
    },
    async submitConvert() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        //form valid değil ise
        return;
      } else {
        const modalConfig = {
          isModalOpened: true,
          modalText: `<b>${this.pointFormatter(
            this.convertingValue,
          )}</b> Puanınız, <b>${this.pointFormatter(this.getConvertingQuantity)}</b> TL olarak
       ${this.selectedCard.desc} İndirimi Cüzdanınıza eklenecektir.`,
          isDoubleButton: true,
          modalType: 'confirmation',
          firstButtonFn: () => this.convertPoints(false),
        };
        this.$store.dispatch('app/setModalConfig', modalConfig);
      }
    },
  },
  computed: {
    totalPoints() {
      if (this.selectedCard && this.selectedCard.desc && this.selectedCard.point > 0) {
        return this.selectedCard.point;
      } else {
        return this.customerDetail.partners.reduce((a, b) => {
          return a + b['point'];
        }, 0);
      }
    },

    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    images() {
      return PARTNER_IMAGES;
    },
    alignment() {
      return FLEX_JUSTIFIES;
    },
    getConvertingQuantity() {
      if (this.convertingValue <= this.totalPoints) return this.convertingValue * this.discountRate;
      else return 0;
    },
  },
  watch: {
    selectedCard: {
      handler(value) {
        if (value) {
          this.$store.dispatch('app/setBackFunction', () => {
            this.selectedCard = null;
            this.isCardsToggled = true;
            this.$store.dispatch('app/setBackFunction', null);
            this.$store.dispatch('app/setBottomBarStatus', true);
          });
        }
      },
    },
  },
  destroyed() {
    this.$store.dispatch('app/setBottomBarStatus', true);
  },
};
</script>
<style scoped lang="scss">
.convert-form {
  .modal-rules-wrapper {
    h1 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
    }
  }
}
.section {
  padding-bottom: palette-space-level('30');

  &-headline {
    padding-bottom: palette-space-level('10');
    line-height: 24px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: palette-space-level('20') palette-space-level('20');
    margin-bottom: palette-space-level('20');
    margin-right: -20px;
    margin-left: -20px;
    background-color: palette-color-level('grey', '10');

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url('~@/assets/images/score.png') no-repeat left center;
      background-size: contain;
      padding-left: palette-space-level('35');
      margin-bottom: palette-space-level('10');

      p:last-child {
        margin-left: palette-space-level('5');
      }
    }
  }
}

.cards {
  &-list {
    $self: &;
    background-image: linear-gradient(
      315deg,
      palette-color-level('fushia', '20'),
      palette-color-level('yellow', '30')
    );
    padding: 2px;
    border-radius: palette-radius-level('4');
    &-select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-radius: palette-radius-level('3');
      overflow: hidden;

      &-points {
        margin-left: palette-space-level('10');
        text-align: center;
        p {
          margin-left: 0 !important;
        }
      }
      &-textButton,
      &-downButton {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: palette-color-level('white', '100');
        border: none !important;
      }
      &-textButton {
        flex: 0 0 calc(100% - 62px);
        max-width: calc(100vw - 40px);
        padding-left: palette-space-level('20') !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &[disabled='disabled'] {
          opacity: 1;
          color: rgba(35, 48, 61, $button-disabled-opacity);
        }

        &_rightText {
          margin: auto;
          display: flex;
          align-items: center;

          p {
            white-space: normal;
          }
          /deep/ & + span {
            margin-left: unset;
          }
        }
      }
      &-downButton {
        flex: 0 0 60px;
        justify-content: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        span {
          margin-right: 0 !important;
        }
      }
    }
    &-image {
      position: relative;
      background-color: palette-color-level('white', '100');
      min-width: palette-space-level(40);
      height: palette-space-level(40);
      border: 1px solid palette-color-level('grey', '15');
      border-radius: palette-radius-level('1');
      margin-right: palette-space-level('20');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      svg {
        position: absolute;
        top: -8px;
        right: -10px;
      }
    }

    &-toggled {
      #{$self} {
        &-dropdown {
          height: auto;
        }
        &-select {
          pointer-events: none;

          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          &-textButton {
            border-bottom-left-radius: 0;
          }
          &-downButton {
            border-bottom-right-radius: 0;
          }
          &-textButton_rightText {
            p:last-child {
              margin-left: palette-space-level('5');
            }
          }
        }
      }
    }

    &-dropdown {
      height: 0;
      overflow: hidden;
      border-bottom-left-radius: palette-radius-level('3');
      border-bottom-right-radius: palette-radius-level('3');

      &-item {
        margin-top: 2px;

        button {
          padding: palette-space-level('10') palette-space-level('20');
          border-top-left-radius: 0;
        }
      }
    }

    &-selectedCard {
      background-color: palette-color-level('white', '100');
      border: none;
      border-radius: palette-radius-level('3');
      padding: palette-space-level('10') palette-space-level('20');
    }
  }

  /deep/ &-convert {
    margin-top: palette-space-level('20');
    label {
      font-size: palette-font-size-level('2');
    }

    .section-headline {
      position: relative;
      padding-right: palette-space-level('40');

      svg {
        position: absolute;
        right: palette-space-level('20');
        top: 2px;
      }
    }

    &-quantity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border: 1px solid palette-color-level('grey', '30');
      border-radius: palette-radius-level('2');
      margin-top: palette-space-level('20');
      padding: palette-space-level('10') palette-space-level('20');

      &-value {
        display: flex;
        align-items: center;
        padding-left: palette-space-level('20');

        p:last-child {
          margin-left: palette-space-level('5');
          align-self: initial;
        }
      }
    }

    &-input {
      width: 100%;
      height: 60px;
      font-size: palette-font-size-level('5');
      color: palette-color-level('grey', '40');
      font-family: $font-family;
      letter-spacing: 0;

      input {
        padding-right: palette-space-level('50');
      }
      &::placeholder {
        color: palette-color-level('grey', '20');
        letter-spacing: normal;
      }
    }
    &-input,
    &-quantity {
      position: relative;

      svg {
        position: absolute;
        right: palette-space-level('20');
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.convert-button {
  position: fixed;
  bottom: 0;
}
.gift-card-button {
  border-radius: 10px;
  margin-top: 20px;
  height: 50px !important;
  background-image: linear-gradient(0, #d20051 10%, #ffa300 160%);
  span:first-child {
    padding-right: 20px !important;
  }
}
</style>
